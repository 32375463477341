import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { GET_EMDR_TRAINING_LEVELS } from '../../../graphql/member';
import { EMDRLevelSelectProps } from './EMDRLevelSelectProps';
import { useEmdrLevels } from './useEmdrLevels';

const EMDRLevelSelect: React.FC<EMDRLevelSelectProps> = ({ name, value, onChange }) => {

    const { data, error } = useQuery(GET_EMDR_TRAINING_LEVELS);
    const { models } = useEmdrLevels({ data });
    const { emdrTrainingLevels } = models;

    useEffect(() => {
        if (error)
            console.log("ERROR", error)
    }, [error])


    return (emdrTrainingLevels ?
        <Form.Select
            as="select"
            name={name}
            value={value}
            onChange={(e) => onChange(e)}
            required
        >
            <option value="">Select training level</option>
            {emdrTrainingLevels.map((option, index) => (
                <option key={index} value={option.emdrTrainingLevelId}>
                    {option.name}
                </option>
            ))}
        </Form.Select>
        : <Spinner size="sm" />
    );
};

export default EMDRLevelSelect;