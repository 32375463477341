import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';


interface TitleSelectProps {
    name: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
}

const TitleSelect: React.FC<TitleSelectProps> = ({ name, value, onChange }) => {

    const titleOptions = [
        'Mx',
        'Mr',
        'Mrs',
        'Miss',
        'Ms',
        'Dr',
        'Prof',
        'Rev',
    ];

    return (
        <Form.Select
            as="select"
            name={name}
            value={value}
            onChange={(e) => onChange(e)}
            required
        >
            <option value="">Select</option>
            {titleOptions.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
            ))}
        </Form.Select>
    );
};

export default TitleSelect;