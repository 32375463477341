import { gql } from '@apollo/client';

export const GET_PROFESSIONALREGISTRATIONS = gql`
  {
    professionalRegistrations {
      name
      professionalRegistrationId
    }
  }
`;

export const GET_PROFESSIONS = gql`
  {
    professions {
      name
      professionId
    }
  }
`;

export const GET_REGIONS = gql`
  {
    regions {
        regionId,
        name
    }
  }
`;
export const GET_EMDR_TRAINING_LEVELS = gql`
  {
    emdrTrainingLevels {
        emdrTrainingLevelId,
        name
    }
  }
`;
export const GET_COUNTRIES = gql`
  {
    countries {
        countryId
        name
    }
  }
`;
export const GET_MEMBERSHIPTYPES = gql`
  {
    membershipTypes {
        membershipTypeId,
        name,
        description
    }
  }
`;


export const CHECK_USERNAME = gql`
  query CheckUsername($username: String!) {
  checkUsername(username: $username)
}
`