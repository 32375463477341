import React from 'react';
import { Form } from 'react-bootstrap';

export const FormContent: React.FC<{ checkFilesize: (event: React.ChangeEvent<HTMLInputElement>) => void; }> = ({
    checkFilesize,
}) => (
    <>
        <Form.Group className="mb-3">
            <Form.Label>Document Name</Form.Label>
            <Form.Control type="text" name="name" placeholder="Document Name" required />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" rows={2} name="description" />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Expiry (if relevant)</Form.Label>
            <Form.Control type="date" name="expires" />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>File</Form.Label>
            <input
                required
                name="file"
                type="file"
                className="form-control"
                accept=".doc,.docx,.pdf,.jpg,.jpeg,.png"
                onChange={checkFilesize}
            ></input>
        </Form.Group>
    </>
);
