import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useParams } from 'react-router-dom';

export const useEventCheckout = () => {
    const { postId } = useParams();


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const quantity = queryParams.get('qty');

    const auth = useAuth();

    const [confirmGuest, setConfirmGuest] = useState(false);
    const [loading, setLoading] = useState(true);

    const onLogin = () => {

        localStorage.setItem("event", JSON.stringify({ postId, quantity }));

        auth.signinRedirect()
    }

    useEffect(() => {

        if (!auth.isLoading && auth.isAuthenticated) {
            setLoading(false);
        }

    }, [auth.isLoading])

    return {
        models: { postId, quantity, auth, confirmGuest, loading },
        operations: { setConfirmGuest, onLogin }
    }
};
