import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React from 'react';
import { ThemeProvider } from 'react-bootstrap';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from "react-oidc-context";
import App from './App';
import { DataContextProvider } from './contexts/DataContext';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const oidcConfig = {
    authority: process.env.REACT_APP_AUTHORITY ?? "",
    client_id: process.env.REACT_APP_CLIENT_ID ?? "",
    client_secret: process.env.REACT_APP_CLIENT_SECRET ?? "",
    redirect_uri: process.env.REACT_APP_REDIRECT_URI ?? "",
    post_logout_redirect_uri: process.env.REACT_APP_POST_REDIRECT_URI ?? "",
    scope: process.env.REACT_APP_SCOPES,
    
};

console.log(oidcConfig);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const onSigninCallback = () => {
    window.location.href = "/dashboard";
}

//const authData = sessionStorage.getItem('oidc.user:https://localhost:7035:' + process.env.REACT_APP_CLIENT_ID);
const authData = sessionStorage.getItem('oidc.user:' + process.env.REACT_APP_AUTHORITY + ':' + process.env.REACT_APP_CLIENT_ID);

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
});

const authLink = setContext((_, { headers }) => {
    var s = JSON.parse(authData ?? "");

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: s.access_token ? `Bearer ${s.access_token}` : "",
        }
    }
});

const commonConfig = {
    cache: new InMemoryCache(),
    connectToDevTools: true
}
const clientConfig = authData ?
    { link: authLink.concat(httpLink) } :
    { uri: process.env.REACT_APP_GRAPHQL_ENDPOINT };

const client = new ApolloClient({
    ...commonConfig,
    ...clientConfig
})

root.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback} >
                <DataContextProvider>
                    <ApolloProvider client={client}>
                        <ThemeProvider prefixes={{}}>
                            <App />
                        </ThemeProvider>
                    </ApolloProvider>
                </DataContextProvider>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
