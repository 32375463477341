import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { LoadingSpinner } from '../../LoadingSpinner';
import { CompleteMessage } from './CompleteMessage';
import { FormContent } from './FormContent';
import { useNewDocumentDialog } from './useNewDocumentDialog';

interface NewDocumentDialogProps {
    buttonTitle: string;
}

export const NewDocumentDialog: React.FC<NewDocumentDialogProps> = ({ buttonTitle }) => {
    const {
        show,
        handleShow,
        handleCancel,
        handleSubmit,
        handleClose,
        checkFilesize,
        disabled,
        validated,
        complete,
    } = useNewDocumentDialog();

    return (
        <>
            <Button variant="outline-primary" onClick={handleShow}>
                {buttonTitle}
            </Button>
            <Modal show={show} onHide={handleCancel}>
                <Form id="form" onSubmit={handleSubmit} noValidate validated={validated}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload Document</Modal.Title>
                    </Modal.Header>

                    {complete ? (<CompleteMessage />) :
                        (<Modal.Body>
                            {disabled ? <LoadingSpinner /> : <FormContent checkFilesize={checkFilesize} />}
                        </Modal.Body>)
                    }

                    <Modal.Footer>
                        {complete ? (<Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>) :
                            (<>
                                <Button variant="secondary" onClick={handleCancel} disabled={disabled}>
                                    Cancel
                                </Button>
                                <Button variant="primary" type="submit" disabled={disabled}>
                                    Upload
                                </Button>
                            </>)}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
