import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const ProfileContainer: React.FC = ({ }) => {

    return (<>
        <Row>
            <Col>

            </Col>
        </Row>
        <Row>
            <Col>
                Placeholder for profile information.
            </Col>
        </Row>
    </>
    );
};
