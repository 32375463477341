import { BillingRequest, BillingRequestFlow } from '@gocardless/react-dropin';
import { method } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Stack } from 'react-bootstrap';
import { useMembership } from '../../hooks/useMembership';
import { GoCardlessButton } from '../Form/PaymentButton/GoCardlessButton';


const useRenewalModal = () => {

    const { models, operations } = useMembership();
    const { membership } = models;
    const { formattedExpiryDate, cancelMembership } = operations;

    const [show, setShow] = useState(false);

    const [subId, setSubId] = useState<string>();
    const [flowId, setFlowId] = useState<string | null>(null);
    const [envId, setEnvId] = useState<string>("");
    const [method, setMethod] = useState<string>();
    const [pendingCancel, setPendingCancel] = useState(false);

    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
        setPendingCancel(false);
        setMethod(undefined);
        setFlowId(null);
    }

    const handleSetupAutorenew = () => {

        setMethod("AUTORENEW");

        operations.initialiseMembershipPayment(false, true, false).then((payment) => {
            if (payment) {
                setFlowId(payment.flow_id);
                setEnvId(payment.environment ?? "")
            }
        });
    }

    const handleSetupRenew = () => {

        setMethod("RENEW");

        operations.initialiseMembershipPayment(true, false, true).then((payment) => {
            if (payment) {
                setFlowId(payment.flow_id);
                setEnvId(payment.environment ?? "")
            }
        });
    }

    const handleSuccess = (billingRequest: BillingRequest, billingRequestFlow: BillingRequestFlow) => {
        operations.finaliseMembershipPayment(billingRequest, billingRequestFlow);
        setFlowId(null);
        setEnvId("");
    };

    const handleCancelAutorenew = (confirm?: boolean) => {
        if (pendingCancel) {
            if (confirm === true) {
                cancelMembership().then(() => {
                    handleClose();
                });
            } else setPendingCancel(false);
        } else setPendingCancel(true);
    }


    useEffect(() => {
        if (models.membership?.subscriptionId !== subId)
            setSubId(models.membership?.subscriptionId ?? undefined);
    })

    return {
        models: { show, expiryDate: formattedExpiryDate(new Date(membership?.endDate)), subId, envId, flowId, pendingCancel, method, membership },
        operations: { handleShow, handleClose, handleSetupAutorenew, handleSetupRenew, handleSuccess, handleCancelAutorenew }
    }

}

const MembershipRenewalModal: React.FC = () => {


    const { models, operations } = useRenewalModal();
    const { handleShow, handleClose, handleCancelAutorenew, handleSetupAutorenew, handleSetupRenew, handleSuccess } = operations;
    const { show, method, subId, flowId, envId, expiryDate, pendingCancel, membership } = models;




    return (
        <>
            <Button variant="default" className="block-action-link small" onClick={handleShow}>
                Manage your membership
            </Button>
            <Modal show={show} onHide={handleClose} size={undefined}>
                <Modal.Header closeButton>
                    <Modal.Title>Membership Renewal Settings</Modal.Title>
                </Modal.Header>

                {pendingCancel === true ?
                    <Modal.Body>
                        <p><b>Are you sure ?</b></p>
                        <p>If you cancel the automatic renewal of your membership you will need to manually renew when it expires to continue accessing the dashboard. </p>
                    </Modal.Body>
                    :
                    <Modal.Body>
                        {flowId === null ?
                            <div>
                                <Row>
                                    <Col>
                                        <span> Auto renewal:</span>

                                    </Col>
                                    <Col>
                                        {subId ? <b>On</b> : <b>Off</b>}

                                    </Col>
                                    <Col xs={"auto"}>
                                        {subId ?
                                            <Button size="sm" variant="outline-danger" onClick={() => handleCancelAutorenew()}>Cancel Autorenewal</Button>
                                            :
                                            <Button size="sm" onClick={() => handleSetupAutorenew()}>Setup Autorenewal</Button>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span> Expires:</span>
                                    </Col>
                                    <Col>
                                        <b>{expiryDate}</b>
                                    </Col>
                                    <Col />
                                </Row>
                                {/* <hr />
                                <Row>
                                    <Col>
                                        <span className="text-muted"> to extend your membership by an additional year</span>

                                    </Col>
                                    <Col xs={"auto"}>
                                       <Button size="sm" variant="outline-primary" onClick={() => handleSetupRenew()}>Renew Now</Button>

                                    </Col>
                                </Row>*/}
                            </div> :
                            <div>

                                {membership?.yearlyPrice &&
                                    <p>Renewal price for this membership is <b>£{membership?.yearlyPrice}</b> </p>    
                                }

                                <p className="text-center my-4">
                                    {method === "RENEW" ?
                                        <>
                                            <GoCardlessButton
                                                billingRequestFlowID={flowId as string}
                                                environment={envId}
                                                onExit={() => { }}
                                                onSuccess={handleSuccess}
                                                label={<>Checkout</>}
                                            />
                                        </> :

                                        <GoCardlessButton
                                            billingRequestFlowID={flowId as string}
                                            environment={envId}
                                            onExit={() => { }}
                                            onSuccess={handleSuccess}
                                            label={<>Setup</>}
                                        />
                                    }
                                </p>


                            </div>
                        }


                    </Modal.Body>

                }

                <Modal.Footer className="justify-content-center">

                    {pendingCancel === true ?
                        <Stack direction="horizontal" className="mx-auto" gap={4}>
                            <Button variant="outline-primary" onClick={() => handleCancelAutorenew()}>Don't Cancel</Button>

                            <Button variant="danger" onClick={() => handleCancelAutorenew(true)}>Cancel Automatic Renewal</Button>
                        </Stack> :
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    }

                </Modal.Footer>
            </Modal>
        </>
    );



};

export default MembershipRenewalModal;