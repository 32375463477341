import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { MembershipCodeForm } from './MembershipCodeForm';
import { MembershipPaymentPrompt } from './MembershipPaymentPrompt';

const MembershipPaymentContainer: React.FC = () => {

    const auth = useAuth();

    return (
        <Row>
            <Col xs={12}><Button
                variant="primary"
                className={"mt-2"}
                style={{ float: 'right' }}
                onClick={() => auth.signoutRedirect()}
            ><small>Sign out</small> <i className="ms-2 bi bi-box-arrow-right"></i> </Button></Col>
            <Col>
                <MembershipPaymentPrompt />
            </Col>
            <Col xs={12}>
                <MembershipCodeForm />
            </Col>
        </Row>

    );
};

export default MembershipPaymentContainer;