import React, { PropsWithChildren, ReactNode, Ref } from 'react';
import { Button, ButtonToolbar, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


interface MembershipFormStepperProps extends PropsWithChildren {
    step: number;
    formRef: React.MutableRefObject<null>;
    handleNextStep: (back?: boolean) => void;
    handleCancel: () => void;
    handleSubmit: (e: React.FormEvent) => void;
    submitted: boolean;
    submitting: boolean;
    allowSubmit: boolean;
}

const FormStepper: React.FC<MembershipFormStepperProps> = ({ step, children, formRef, handleNextStep, handleCancel, handleSubmit, submitted, submitting, allowSubmit }) => {

    const navigate = useNavigate();

    const stepDescriptions = [
        <span>Contact info</span>,
        <span>Your experience</span>,
        <span>Your membership</span>,
    ];

    const totalSteps = stepDescriptions.length;

    const getStepIcons = () => {
        const icons = [];
        for (let i = 0; i < totalSteps; i++) {
            icons.push(
                <div key={i} className="d-flex flex-column justify-content-between align-items-between text-center mx-3">
                    <div className="d-flex flex-column align-items-center" style={{ width: "140px" }}>
                        <i
                            className={`bi bi-${step >= (i + 2) ? "check" : (i + 1)}-circle-fill me-2 fs-5 text-${step >= (i + 1) ? "success" : "primary"} `}
                            style={{ opacity: step >= (i + 1) ? 1 : 0.8 }}>
                        </i>

                        {i !== (totalSteps - 1) &&
                            <div className={`bg-${step >= (i + 2) ? "success" : "primary"}`} style={{ position: "absolute", marginTop: 12, marginRight: -165, width: "140px", height: "5px", borderRadius: "50px", opacity: step >= (i + 2) ? 1 : 0.8 }}></div>
                        }
                    </div>

                    <p style={step === (i + 1) ? { fontWeight: 'bold' } : undefined} >{stepDescriptions[i]}</p>
                </div>
            );
        }
        return icons;
    }

    const progress = (e: React.FormEvent, back?: boolean) => {
        scrollToTop();


        back ? step === 1 ? handleCancel() : handleNextStep(back)
            : allowSubmit ? handleSubmit(e) : handleNextStep();
    }

    const scrollToTop = () => {
        let c = formRef.current as any;
        if (c) c.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Row className="my-4 ">
                <Col>
                    <p className="d-flex justify-content-center align-items-center">
                        {getStepIcons()}
                    </p>
                </Col>
            </Row>
            {children}
            <ButtonToolbar className="w-100 mt-4 d-flex justify-content-between">
                {submitted ? <Button className="my-4 mx-auto" size="lg" style={{ minWidth: "170px" }} onClick={() => navigate("/dashboard")}>Sign In</Button> : <>

                    <Button
                        variant="outline-primary"
                        onClick={(e) => step === 1 ? handleCancel() : progress(e, true)}
                        disabled={submitted}
                        style={{ borderRadius: 0 }}

                    >
                        <i className="bi bi-arrow-left pe-2"></i>
                        {step === 1 ? "Cancel" : "Back"}
                    </Button>

                    {!allowSubmit &&
                        <Button variant="info" onClick={(e) => progress(e)} style={{ borderRadius: 0 }}

                        >
                            <>Next<i className="bi bi-arrow-right ps-2"></i></>
                        </Button>}
                    {allowSubmit && !submitting &&
                        <Button onClick={(e) => progress(e)} style={{ borderRadius: 0 }}>
                            <> Join Now<i className="bi bi-check ps-2"></i></>
                        </Button>}
                    {allowSubmit && submitting &&
                        <Button disabled style={{ borderRadius: 0 }}>
                            <> Join Now


                                <Spinner style={{ marginLeft: "1em" }} animation="border" role="status" size="sm">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>


                            </>
                        </Button>}

                </>}
            </ButtonToolbar>
        </>

    );
};

export default FormStepper;