import React from 'react';
import { MemberDocument } from '../../graphql/__generated__/graphql';
import { useDocuments } from '../../hooks/useDocuments';
import './index.scss';

export interface IDocumentItemProps {
    document: MemberDocument;
    compact?: boolean;
}
export const DocumentItem: React.FC<IDocumentItemProps> = ({ document, compact }) => {

    const { operations } = useDocuments();

    const deleteDocument = () => {

        if (document.cpd && document.cpd > 0) {
            window.alert("You are unable to delete this document due to assigned CPD.");
        } else {
            if (window.confirm("Are you sure want to delete document '" + document.name + "'?\n\nThis action can't be undone.")) {
                operations.deleteDocument(document.id);
            }
        }
    }

    const expiryDate = new Date(document.expiries);
    const flag = expiryDate <= new Date(new Date().setMonth(new Date().getMonth() + 1));

    return (<>
        <tr key={document.id}>
            <td>
                <span className={flag && document.expiries ? "text-danger" : undefined}>
                    {flag && document.expiries && <i style={{ marginRight: "1em" }} className="bi bi-exclamation-circle-fill"></i>}
                    {document.name}
                </span>
            </td>
            {!compact && <>
                <td>
                    {document.expiries != null ? expiryDate.toLocaleDateString() : "N/A"}
                </td>
                <td>
                    {document.cpd != 0 ? document.cpd : "N/A"}
                </td>
            </>}
            <td align="right">
                <div className="icon">
                    <a onClick={() => operations.downloadDocument(document.id)} href="#" title="Download Document">
                        <i className="bi bi-cloud-arrow-down"></i>
                    </a>
                </div>
                <div className="icon">
                    <a onClick={() => operations.shareDocument(document.id)} href="#" title="Share Document">
                        <i className="bi bi-share"></i>
                    </a>
                </div>
                <div className="icon">
                    {document.cpd && document.cpd > 0 ?
                        <i className="bi bi-trash3" style={{ color: "silver" }}></i>
                        :
                        <a onClick={() => deleteDocument()} href="#" title="Delete Document">
                            <i className="bi bi-trash3"></i>
                        </a>
                    }
                </div>
            </td>
        </tr>
    </>);
}