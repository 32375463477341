import { useState } from 'react';

export const useLanguages = () => {

    const [languages, setLanguages] = useState<string[]>([
        "Arabic",
        "Bengali",
        "Chinese (Cantonese and Mandarin)",
        "French",
        "German",
        "Gujarati",
        "Italian",
        "Lithuanian",
        "Persian/Farsi",
        "Polish",
        "Portuguese",
        "Punjabi",
        "Romanian",
        "Somali",
        "Spanish",
        "Tagalog/Filipino",
        "Tamil",
        "Turkish",
        "Urdu"
    ]);

    return {
        models: { languages },
    };
};
