import { PayPalButtons, PayPalScriptProvider, ReactPayPalScriptOptions } from "@paypal/react-paypal-js";
import { ReactNode, useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { LoadingSpinner } from "../../LoadingSpinner";


type PaymentButtonDropin = {
    label: ReactNode,
    disabled?: boolean,
    environment?: string,
    cart: any
    onSuccess: () => void,
    onExit: (error: object | null, metadata: object) => void
}

export const usePayPal = (cart: any) => {

    const auth = useAuth();

    const [message, setMessage] = useState("");
    const [items, setItems] = useState(null);


    const initialOptions = {
        "clientId": process.env.REACT_APP_PAYPAL_CLIENTID,
        "enableFunding": "card",
        "disableFunding": "paylater,venmo",
        "dataSdkIntegrationSource": "integrationbuilder_sc",
        "currency": "GBP"
    } as ReactPayPalScriptOptions;

    useEffect(() => {

        if (cart.items !== items) {
            setItems(null);
            setTimeout(() => {
                if (cart && cart.items)
                    setItems(cart.items);
            }, 100);
        }
    }, [cart, items])

    return {
        models: { initialOptions, accessToken: auth.user?.access_token, message, items },
        operations: { setMessage }
    }



}



export const PayPalButton = (props: PaymentButtonDropin) => {

    const { disabled, cart, onSuccess } = props;

    const { models, operations } = usePayPal(cart);
    const { initialOptions, accessToken, items, message } = models;
    const { setMessage } = operations

    return (

        <div className="mx-auto" style={{ width: '400px' }}>
            <PayPalScriptProvider options={initialOptions}>
                {items === null ? <LoadingSpinner /> : <PayPalButtons
                    style={{
                        shape: "pill",
                        layout: "vertical",
                        color: "gold"

                    }}
                    createOrder={async () => {
                        try {
                            const response = await fetch(process.env.REACT_APP_ORDERAPI_ENDPOINT ?? "", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    'Authorization': 'Bearer ' + accessToken

                                },
                                body: JSON.stringify({ items }),
                            });

                            const orderData = await response.json();


                            console.log("ORDER CREATE", orderData, response);


                            if (orderData.orderId) {
                                return orderData.orderId;
                            } else {
                                const errorDetail = orderData?.details?.[0];
                                const errorMessage = errorDetail
                                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                    : JSON.stringify(orderData);

                                throw new Error(errorMessage);
                            }
                        } catch (error) {
                            console.error(error);
                            setMessage(`Could not initiate PayPal Checkout...${error}`);
                        }
                    }}
                    onApprove={async (data, actions) => {
                        try {
                            const response = await fetch(
                                process.env.REACT_APP_ORDERAPI_ENDPOINT + `/${data.orderID}/capture`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        'Authorization': 'Bearer ' + accessToken

                                    },
                                    body: JSON.stringify({ items }),
                                },
                            );

                            const orderData = (await response.json()).value;

                            console.log("ORDER DATA", orderData);


                            const errorDetail = orderData?.details?.[0];

                            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                return actions.restart();
                            } else if (errorDetail) {
                                // (2) Other non-recoverable errors -> Show a failure message
                                throw new Error(
                                    `${errorDetail.description} (${orderData.debug_id})`,
                                );
                            } else {
                                onSuccess();

                                const transaction =
                                    orderData.purchase_units[0].payments.captures[0];
                                setMessage(
                                    `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                                );
                                console.log(
                                    "Capture result",
                                    orderData,
                                    JSON.stringify(orderData, null, 2),
                                );
                            }
                        } catch (error) {
                            console.error(error);
                            setMessage(
                                `Sorry, your transaction could not be processed...${error}`,
                            );
                        }
                    }}
                    disabled={disabled === true}
                />
                }
            </PayPalScriptProvider>
            <p>{message}</p>
        </div>

    );
};
