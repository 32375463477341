import React, { useState } from 'react';
import { useDocuments } from '../../../hooks/useDocuments';

export const useNewDocumentDialog = () => {
    const [show, setShow] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [validated, setValidated] = useState(false);
    const [complete, setComplete] = useState<boolean>(false);

    const { operations } = useDocuments();

    const handleCancel = () => {
        setShow(false);
        setValidated(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setValidated(true);

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setDisabled(false);
            event.stopPropagation();
        } else {
            setDisabled(true);
            operations.addDocument(event).then((result) => {
                if (result) {
                    setComplete(true);
                }
            });
        }
    };

    const handleClose = () => {
        setDisabled(false);
        setShow(false);
        setComplete(false);
    };

    const checkFilesize = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.item(0);
        if (file) {
            const kb = file.size / 1024;
            if (kb / 1024 > 3) {
                alert('The chosen file is too large, files must be 3mb or less.');
                event.currentTarget.type = '';
                event.currentTarget.value = '';
                event.currentTarget.type = 'file';
            }
        }
    };

    return {
        show,
        handleShow,
        handleCancel,
        handleSubmit,
        handleClose,
        checkFilesize,
        disabled,
        validated,
        complete,
    };
};
