import React from 'react';
import { Badge, Card, Col, Container, Row } from 'react-bootstrap';
import { EditableAvatarContainer as EditableAvatar } from '../EditableAvatar';
import { EditableContactContainer as EditableContact } from '../EditableContact';
import { EditableNameContainer as EditableName } from '../EditableName';

interface IUserProfile { }

const UserProfile: React.FC<IUserProfile> = ({

}) => {

    return (
        <Card className="mb-3" border="light">
            <Card.Body>
                <Container>
                    <Row>
                        <Col className="d-flex flex-column align-items-center">
                            <EditableAvatar />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        {/*    <Badge bg="secondary">Not Accredited</Badge>*/} 
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditableName />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EditableContact />
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
};

export default UserProfile;