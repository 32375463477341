//import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
//import { GET_PROFILE } from '../../../graphql/profile/queries';

interface IWelcome {

}

const ExpiredMembershipPopup: React.FC<IWelcome> = ({
}) => {

    //const { data, error, loading } = useQuery(GET_PROFILE);
    //const [name, setName] = useState("");

    //useEffect(() => {
    //    if (!loading) {
    //        setName(data.profile?.firstName);
    //    }
    //});

    return (
        (1 == 1) ? <></>
            :
                                                                 <div className="expired-membershop-popup">
                              <div style={{ position: "absolute", background: "white" }}>
                                         <div className="welcome-title-name">Your membership has expired...</div>
   <div className="welcome-title-name">Stuff for renewal etc.</div>
            </div>
        </div >
    );
};

export default ExpiredMembershipPopup;