import { Card, CardBody, Table } from "react-bootstrap";
import { useInvoiceHistory } from "../../../hooks/useInvoiceHistory";
import { LoadingError } from "../../LoadingError";
import { LoadingSpinner } from "../../LoadingSpinner";
import { Invoice } from "./Invoice";

export const InvoiceHistoryContainer: React.FC = () => {

    const { models } = useInvoiceHistory();
    const { loading, error, invoiceHistory } = models;

    return (
        <Card border="light">
            <CardBody>
                <h4>Payment history
                </h4>
                {loading &&
                    <div>
                        <LoadingSpinner ></LoadingSpinner>
                    </div>
                }

                {!loading && invoiceHistory &&
                    <Table striped hover>
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>Date</td>
                                <td>Description</td>
                                <td>Value</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoiceHistory.map((invoice, index) => (

                                    <Invoice invoice={invoice} />
                                ))
                            }
                        </tbody>
                    </Table>
                }
                {error && <LoadingError></LoadingError>}
            </CardBody>
        </Card >
    );
};
