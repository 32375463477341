import { useMutation, useQuery } from '@apollo/client';
import { BillingRequest, BillingRequestFlow } from '@gocardless/react-dropin';
import { useEffect, useState } from 'react';
import { MEMBERSHIP_PAYMENT, MEMBERSHIP_PAYMENT_BYPASS, MEMBERSHIP_PAYMENT_CANCEL, MEMBERSHIP_PAYMENT_RESPONSE } from '../graphql/payment/mutations';
import { GET_MEMBERSHIP } from '../graphql/profile/queries';
import { MemberMembership, MembershipPaymentBypassCommandInput, MembershipPaymentCancelCommandInput, MembershipPaymentCommandInput, MembershipPaymentCompleteCommandInput } from '../graphql/__generated__/graphql';

export const useMembership = () => {

    const [membershipPaymentMutation] = useMutation(MEMBERSHIP_PAYMENT);
    const [membershipPaymentResponseMutation] = useMutation(MEMBERSHIP_PAYMENT_RESPONSE);
    const [membershipPaymentCancelMutation] = useMutation(MEMBERSHIP_PAYMENT_CANCEL);
    const [membershipPaymentBypassMutation] = useMutation(MEMBERSHIP_PAYMENT_BYPASS);

    const { data, error, loading, refetch } = useQuery(GET_MEMBERSHIP);

    const [membership, setMembership] = useState<MemberMembership>();


    //cancel autorenew - stop the recurring payment and remove the subcriptionid

    //setup autorenew - gocardless setup without initial payment

    //renew now - gocardless instant payment - no recurring payment subscription..

    const initialiseMembershipPayment = (renewal: boolean, automaticRenewal: boolean, instantPayment: boolean) => {
        return membershipPaymentMutation({
            variables: {
                command: {
                    redirectUri: window.location.href,
                    renewal: renewal,
                    automaticRenewal: automaticRenewal,
                    instantPayment: instantPayment
                } as MembershipPaymentCommandInput
            }
        }).then(async (response: any) => {
            return response.data.membershipPayment;
        }).catch((e: any) => {
            console.log("Failed to initialise.", e);
        });
    }

    const finaliseMembershipPayment = (billingRequest: BillingRequest, billingRequestFlow: BillingRequestFlow, reload?: boolean) => {

        console.log("billing", billingRequest);

        membershipPaymentResponseMutation({
            variables: {
                command: {
                    created: billingRequest.created_at,
                    id: billingRequest.id,
                    status: billingRequest.status,
                    billingRequestJson: JSON.stringify(billingRequest),
                    billingRequestFlowJson: JSON.stringify(billingRequestFlow),
                    renew: billingRequest.payment_request !== null
                } as MembershipPaymentCompleteCommandInput
            }
        }).then(() => {
            if (reload === true)
                window.location.reload();

            else refetch().then((resp) => {
                setMembership(resp.data.membership);
            });
        });
    }

    const bypassMembershipPayment = async (id: string, code: string) => {

        return new Promise((resolve, reject) => {
            membershipPaymentBypassMutation({
                variables: {
                    command: {
                        id: id,
                        code: code
                    } as MembershipPaymentBypassCommandInput
                }
            }).then((response) => {
                let result = response?.data?.membershipPaymentBypass;
                if (result) {
                    if (result.success === true) {
                        resolve(true);
                    } else {
                        reject("The code you entered is not valid.")
                    }
                }
            }).catch((e) => {
                console.log(e);
                reject("An unexpected error occurred, please try again.")
            });
        });
    }

    const cancelMembership = async () => {
        return new Promise((resolve, reject) => {
            membershipPaymentCancelMutation({
                variables: {
                    command: {
                        memberId: membership?.memberId
                    } as MembershipPaymentCancelCommandInput
                }
            }).then((resp) => {
                //let result = resp?.data?.membershipPaymentCancel;

                refetch().then((resp) => {
                    setMembership(resp.data.membership);
                    resolve(true);
                });

                resolve(false);
            }).catch((e) => reject(e))

        });
    }

    const formattedExpiryDate = (date: Date) => {

        return date.getDate() + "/" + (date.getMonth()+1 /*zero based*/) + "/" + date.getFullYear();
    }

    useEffect(() => {
        if (!loading && !error) {

            setMembership(data.membership);
        }

        if (error)
            console.log("MembershipBlock Error", error);
    });

    return {
        models: { membership, error, loading },
        operations: { formattedExpiryDate, cancelMembership, initialiseMembershipPayment, finaliseMembershipPayment, bypassMembershipPayment }
    };
};
