import React from 'react';
import { Alert, Badge, Button, ButtonGroup, Modal } from 'react-bootstrap';
import { MemberEventModalProps } from './MemberEventModalProps';
import { useMemberEvent } from './useMemberEvent';


const MemberEventModal: React.FC<MemberEventModalProps> = ({ ...props }) => {

    const { memberEvent } = { ...props } as MemberEventModalProps;
    const { models, operations } = useMemberEvent({ ...props });
    const { show, loading, videoSrc, expired } = models;
    const { handleShow, handleClose, handleSurveyOpened } = operations;

    return (
        <>
            <Button variant="outline-light" onClick={handleShow}>
                Rewatch Event
            </Button>
            <Modal show={show} onHide={handleClose} size={(videoSrc && !expired) ? "xl" : undefined}>
                <Modal.Header closeButton>
                    <Modal.Title>{memberEvent?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {videoSrc ? expired ? <p>The video for this event is no longer available.</p> :

                        <div className="video-container">
                            <video
                                controls
                                controlsList="nodownload"
                                onContextMenu={(e) => { e.preventDefault() }}
                                width="100%">
                                <source src={videoSrc} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="watermark"> Member ID {memberEvent?.memberId}. Do not distribute.</div>
                        </div>
                        :
                        <p>There is no video available for this event.</p>
                    }
                    {memberEvent?.surveyHref &&
                        <Alert variant="light">
                            <p>Now that this event has finished, you can claim <b>CPD</b> by completing a short survey, just follow the link below.</p>
                            <p>You must claim CPD to ensure it is issued with a certificate of participation when the event has expired. CPD awarded certificates are visible in your <b>Documents</b> area. </p>
                            <Badge pill
                                bg={expired ? "danger" : "primary"}
                            >expires  {new Date(memberEvent.expiryDate).toLocaleDateString()}</Badge>

                        </Alert>
                    }
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <ButtonGroup>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {memberEvent?.surveyHref &&
                            <Button variant="primary" as={'a'} href={memberEvent.surveyHref} target="_blank" onClick={handleSurveyOpened}>
                                Claim CPD
                            </Button>
                        }
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    );



};

export default MemberEventModal;