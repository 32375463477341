import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AccountContainer as Account } from '../Accounting/Account';
import { InvoiceHistoryContainer as InvoiceHistory } from '../Accounting/InvoiceHistory';
import MembershipBlock from '../Blocks/MembershipBlock';
import MembershipBlockCTA from '../Blocks/MembershipBlock/MembershipBlockCTA';

const MembershipContainer: React.FC = () => {
    return (
        <>
            <Row>
                <Col md={4}>
                    <MembershipBlock callToAction={<MembershipBlockCTA />} />
                </Col>
                <Col md={8}>
                    <Account />
                </Col>
            </Row>

            <Row>
                <Col>
                    <InvoiceHistory />
                </Col>
            </Row>
            <Row>
                <Col className="p-4">
                    <a href="https://emdrassociation.org.uk/members/regional-and-special-interest-groups/">
                        Looking for Regional Groups?
                    </a>
                </Col>
            </Row>
        </>
    );
};

export default MembershipContainer;