import { gql } from '@apollo/client';

export const CREATE_MEMBER = gql`
  mutation memberCreateMutation($member:CreateMemberCommandInput) {
    memberCreate(member: $member) {
        memberId
        title
        firstname
        lastname
        email
        website
        qualifications
        childTrainingLevel
        childTrainingLevelDateAchieved
        region {
            regionId
            name
        }
        addresses{
          addressId
          addressLine1
          addressLine2
        }
        professions{
          professionId
          name
        }
        professionalRegistrations{
          professionalRegistrationId
          name
        }
    }
  }
`;


//export const UPDATE_MEMBER = gql`
//  mutation memberUpdateMutation($member:UpdateMemberCommandInput) {
//    memberUpdate(member: $member) {
// memberId
//        title
//        firstname
//        lastname
//        email
//        website
//        qualifications
//        childTrainingLevel
//        childTrainingLevelDateAchieved
//        region {
//            regionId
//            name
//        }
//        addresses{
//          addressId
//          addressLine1
//          addressLine2
//        }
//        professions{
//          professionId
//          name
//        }
//        professionalRegistrations{
//          professionalRegistrationId
//          name
//        }    }
//  }
//`;
