import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Badge, Button } from 'react-bootstrap';
import { GET_ACCREDITATION_TYPE } from '../../../graphql/profile/queries';
import { MemberAccreditation } from '../../../graphql/__generated__/graphql';
import { LoadingError } from '../../LoadingError';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Block } from '../Block';

interface IAccreditationBlock {
    displayAction: boolean;
}

const AccreditationBlock: React.FC<IAccreditationBlock> = ({ displayAction }) => {

    const { data, error, loading } = useQuery(GET_ACCREDITATION_TYPE);
    const [accreditation, setAccreditation] = useState<MemberAccreditation>();

    useEffect(() => {
        if (!loading && !error) {
            setAccreditation(data.accreditation);
        }
        if (error)
            console.log("AccreditationBlock Error", error);
    }, [loading, error, data]);

    return (
        <>
            {!loading && data?.accreditation &&
                <Block title="Accreditation" bg="ocean" fill="ocean" link={displayAction ? { title: "Manage your accreditation", href: "/accreditation" } : undefined}>

                    {loading && <LoadingSpinner />}
                    {error && <LoadingError />}
                    {!loading && !error &&
                        <>
                            <Row>
                                <Col>
                                    <div className="membership-block-membership">{accreditation?.type}</div>
                                </Col>
                                <Col>
                                    <div style={{ marginBottom: "0.5em" }}>
                                        {accreditation?.active ?
                                            <Badge bg="primary" className="membership-block-membership">Active</Badge>
                                            :
                                            <Badge bg="danger" className="membership-block-membership">Expired</Badge>
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <div className="membership-block-membershiprenews">Renewal date: {new Date(accreditation?.endDate).toDateString()}</div>

                            {!accreditation?.active &&
                                <Button className="membership-block-membershipnumber" style={{ width: "100%", textAlign: "center", fontSize: "1em" }} onClick={() => { window.alert("take user to renewal screen") }}>Renew</Button>
                            }
                        </>
                    }
                </Block>
            }</>
    );
};

export default AccreditationBlock;