import React, { useEffect, useState } from 'react';
import { IEditableNameFormProps } from "./IEditableNameFormProps";

export const useEditableName = ({ data }: any) => {

    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState<IEditableNameFormProps>();

    const handleInputChangeEvent = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ): void => {
        const { name, value } = event.target;
        handleInputChange(name, value);
    };

    const handleInputChange = (
        name: string,
        value: string | string[] | boolean
    ): void => {

        setFormData((prevData: any) => (
            {
                ...prevData,
                [name]: value,
            }));
    };

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    useEffect(() => {
        if (data?.profile && !formData) {
            setFormData({
                id: data?.profile?.id ?? '',
                title: data?.profile?.title ?? '',
                firstName: data?.profile?.firstName ?? '',
                lastName: data?.profile?.lastName ?? ''
            });
        }
    })

    return {
        models: { show, formData },
        operations: { handleInputChangeEvent, handleOpen, handleClose }
    };

};
