import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';
import { AccreditationContainer as Accreditation } from './components/Accreditation';
import AuthorisedLayout from './components/AuthorisedLayout';
import { DocumentsContainer as Documents } from './components/Documents';
import { DocumentSharingContainer as DocumentSharing } from './components/DocumentSharing';
import EventCheckout from './components/EventCheckout';
import { EventsContainer as Events } from './components/Events';
import Home from './components/Home';
import Layout from './components/Layout';
import MemberDashboard from './components/MemberDashboard';
import Membership from './components/Membership';
import MembershipJoinForm from './components/MembershipJoinForm';
import { ProfileContainer as Profile } from './components/Profile';
import TestEventPage from './components/TestEventPage';
import { PrivateRoute } from './PrivateRoute';
import { RouteWrapper } from './RouteWrapper';
function App() {

    const [memberExpired, setMemberExpired] = useState(false);

    return (
        <div className="App">
            <Container className="foobar bg-light">
                <RouteWrapper handleMembershipExpiryStatus={(expired) => setMemberExpired(expired)}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/become-a-member" element={
                                <Layout>
                                    <MembershipJoinForm nonUkResident={false} />
                                </Layout>
                            } />

                            {/*<Route path="/become-a-nonuk-member" element={*/}
                            {/*    <Layout>*/}
                            {/*        <MembershipJoinForm nonUkResident={true} />*/}
                            {/*    </Layout>*/}
                            {/*} />*/}

                            <Route path="/dashboard" element={
                                <PrivateRoute expiredMembership={memberExpired}>
                                    <AuthorisedLayout>
                                        <MemberDashboard />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            } />

                            <Route path="/membership" element={
                                <PrivateRoute expiredMembership={memberExpired}>
                                    <AuthorisedLayout>
                                        <Membership />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            } />
                            <Route path="/documents" element={
                                <PrivateRoute expiredMembership={memberExpired}>
                                    <AuthorisedLayout>
                                        <Documents />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            } />

                            <Route path="/events" element={
                                <PrivateRoute expiredMembership={memberExpired}>
                                    <AuthorisedLayout>
                                        <Events />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            } />

                            <Route path="/accreditation" element={
                                <PrivateRoute expiredMembership={memberExpired}>
                                    <AuthorisedLayout>
                                        <Accreditation />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            } />

                            <Route path="/profile" element={
                                <PrivateRoute expiredMembership={memberExpired}>
                                    <AuthorisedLayout>
                                        <Profile />
                                    </AuthorisedLayout>
                                </PrivateRoute>
                            } />

                            <Route path="/shared/:id" element={
                                <Layout>
                                    <DocumentSharing />
                                </Layout>
                            } />

                            <Route path="/signin-success" element={<MemberDashboard />} />

                            <Route path="/testevent" element={
                                <TestEventPage />
                            } />
                            <Route path="/book/:postId/" element={
                                <EventCheckout />
                            } />
                        </Routes>
                    </BrowserRouter>

                </RouteWrapper>
            </Container>
        </div>
    );
}

export default App;
