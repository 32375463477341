import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';

export const AccreditationContainer: React.FC = ({ }) => {

    return (<>
        <Row>
            <Col>

            </Col>
        </Row>
        <Row>
            <Col>
                <Alert variant="warning" className="my-4">
                    <p><i className="bi bi-cone-striped me-2"></i>Accreditation is coming soon. </p>
                    <p>You will be able to begin, renew and track your accreditation status from this area once it is complete.</p>
                 

                    <p>Meantime, learn more about how to apply for accreditation, including the pilot of the digitised application system, on the public site: </p>
                    <a href="https://emdrassociation.org.uk/accreditation/">https://emdrassociation.org.uk/accreditation/</a>
                </Alert>
            </Col>
        </Row>
    </>
    );
};
