import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GET_ACCOUNT } from '../../../graphql/accounting/queries';
import { MemberAccount } from '../../../graphql/__generated__/graphql';
import { Block } from '../../Blocks/Block';
import { LoadingError } from '../../LoadingError';
import { LoadingSpinner } from '../../LoadingSpinner';

export const AccountContainer: React.FC = () => {

    const { data, error, loading } = useQuery(GET_ACCOUNT);
    const [account, setAccount] = useState<MemberAccount>();

    useEffect(() => {
        if (!loading && !error) {
            setAccount(data.memberAccount);
        }
        if (error)
            console.log(error);
    }, [loading, error, data]);

    let ref = account && account.id !== 0 ? "#" + account.id : "N/A";

    return (
        <Block title={"Account Reference: " + ref} bg="lime" fill="lime">

            {loading && <LoadingSpinner></LoadingSpinner>}
            {error && <LoadingError></LoadingError>}
            {!loading && !error && account?.id !== 0 &&
                <>
                    <div style={{ fontWeight: "bold" }}>
                        {account?.companyName}
                    </div>

                    <div style={{ fontWeight: "bold" }}>
                        Billing Address
                    </div>

                    <div style={{ paddingLeft: "1em" }} >
                        {
                            account?.billingAddress?.line1 && <div>{account?.billingAddress.line1} {account?.billingAddress.line2 && <>,{account?.billingAddress.line2}</>}</div>
                        }
                        {
                            account?.billingAddress?.city && <div>{account?.billingAddress.city}</div>
                        }
                        {
                            account?.billingAddress?.county && <div>{account?.billingAddress.county}</div>
                        }
                        {
                            account?.billingAddress?.postcode && <div>{account?.billingAddress.postcode}</div>
                        }
                        {
                            account?.billingAddress?.country && <div>{account?.billingAddress.country}</div>
                        }
                    </div>
                </>                
            }
            {!loading && !error && account?.id === 0 && <div>No account available, please contact the EMDR administrators.</div>}
            
        </Block>
    );
};