import React, { ReactNode } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import imgSrc from '../../logo.png';

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {

    return (
        <Container >
            {/*<Row>*/}
            {/*    <Col xs={12} className="text-center my-4">*/}
            {/*        <img src={imgSrc}  width="200"></img>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row className="py-4">
                <Col xs={12} className="text-center">
                    {children}
                </Col>
            </Row>
        </Container>

    );
};

export default Layout;