import { PropsWithChildren, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import MembershipPaymentContainer from './components/MembershipPayment';


interface PrivateRouteProps extends PropsWithChildren {

    expiredMembership: boolean
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ expiredMembership, children }) => {

    const auth = useAuth();


    useEffect(() => {
        if (auth.isAuthenticated === false && !auth.isLoading)
            auth.signinRedirect();
    });

    useEffect(() => {
        if (auth.isAuthenticated) {
            var current = localStorage.getItem("event");
            if (current) {
                localStorage.removeItem("event");
                let booking = JSON.parse(current);
                if (booking.postId && booking.quantity) {
                    window.location.href = ("/book/" + booking.postId + "?qty=" + booking.quantity);
                }
            }
        }
    }, [auth.isAuthenticated, expiredMembership])


    return (auth.isAuthenticated === true) ? (expiredMembership ? <MembershipPaymentContainer /> : <> {children}</>)

        :
        <div className="p-4">
            <Spinner role="status">
                <span className="visually-hidden">Loading authentication...</span>
            </Spinner>
            <small className="mx-2">Getting profile</small>

        </div>
        ;

};