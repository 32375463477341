import React, { useRef, useState } from 'react';
import * as imageConversion from 'image-conversion';
import { IEditableAvatarFormProps } from './IEditableAvatarFormProps';

export const useEditableAvatar = ({ data }: any) => {

    const [show, setShow] = useState(false);
    const [imageData, setImageData] = useState<string | null>(data?.profile?.avatar ?? null);
    const [formData, setFormData] = useState<IEditableAvatarFormProps>();
    const inputRef = useRef<HTMLInputElement | null>(null);

    async function convertToWebP(file: File): Promise<string> {

        let dataUrl = '';

        await imageConversion.compressAccurately(file, {
            format: 'webp',
            size: 100,
            quality: 0.7, // Adjust the quality as needed
        }).then(async (res) => {
            dataUrl = await imageConversion.filetoDataURL(res);
        });
        return dataUrl;
    }

    const updateAvatarPhoto = () => {    
        handleClose();      
    }
    const handleClose = () => {
        setShow(false);        
    }
    const handleOpen = () => {
        setShow(true);       
    }
    const handleImageUpload = () => {
        if (inputRef.current?.files && inputRef.current?.files[0]) {
            const file = inputRef.current.files[0];

            convertToWebP(file)
                .then(compressedDataUrl => {
                    setImageData(compressedDataUrl);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const resetAvatarPhoto = () => {
        setImageData(null);
    }

    return {
        models: { show, inputRef, imageData },
        operations: { handleImageUpload, handleOpen, handleClose, resetAvatarPhoto }
    };
}
