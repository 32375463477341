import { gql } from '@apollo/client';


export const eventVideoGQL = `
        videoId
        name
        description
`;

export const eventGQL = `
    cancelled
    createdDate
    eventId
    postId
    cpd
    childAndAdolescent
    eventPostHref
    name
    participants {
        cancelled
        createdDate
        eventId
        eventParticipantId
        memberId
    }
    videos {
        videoId
    }
    memberPrice
    nonMemberPrice
    startDate
    endDate
    expiryDate
`;
export const GET_EVENT_VIDEOS = gql`
  query GetEventVideos($id:String!) {
    eventVideos(id:$id){
            ${eventVideoGQL}    
    }
}
`;


export const GET_EVENT_BY_POST_ID = gql`
 query GetEventByPostId($id:String!){
    eventByPostId(id:$id){
        ${eventGQL}
    }
  }
`;