import { BillingRequest, BillingRequestFlow } from '@gocardless/react-dropin';
import React, { useEffect, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useMembership } from '../../hooks/useMembership';
import { GoCardlessButton } from '../Form/PaymentButton/GoCardlessButton';

export const MembershipPaymentPrompt: React.FC = () => {


    const { operations } = useMembership();
    const [flowID, setFlowID] = useState<string | null>(null);
    const [envID, setEnvID] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const handleSuccess = (billingRequest: BillingRequest, billingRequestFlow: BillingRequestFlow) => {

        operations.finaliseMembershipPayment(billingRequest, billingRequestFlow, true);

    };

    const handleExit = (error: object | null, metadata: object) => {
        // window.location.reload();
    };

    useEffect(() => {
        if (flowID === null) {
            operations.initialiseMembershipPayment(false, true, true).then((payment) => {
                if (payment) {
                    setFlowID(payment.flow_id);
                    setEnvID(payment.environment ?? "")
                }
            }).catch((e) => setError(e));
        }
    }, [flowID]);

    return (
        <Card border="light" className="w-50 m-auto mt-4 p-4 text-primary" style={{ maxWidth: '600px' }}>
            <Card.Body>
                <h2><b>Payment Details Required</b></h2>
                <p>To access the dashboard, you must have a valid payment method. Please click on the setup button below to get started.</p>
                <p className="">The status of any accreditations you have may be void if you do not uphold your membership.</p>
                {!error &&
                    <div className="w-100 text-center py-4">
                        {flowID === null ? (<p>loading....</p>) : (
                            <GoCardlessButton
                                billingRequestFlowID={flowID as string}
                                environment={envID}
                                onExit={handleExit}
                                onSuccess={handleSuccess}
                                label={<>Setup Now</>}
                            />
                        )}
                    </div>
                }
                {error &&
                    <div>Payments are currently offline, please try again later.</div>
                }
                <Alert className="m-0 mt-4">
                    <small>
                        <b>Can't access your dashboard?</b>
                        <div>If you believe you are seeing this message in error please contact support to check your membership's status.</div>
                    </small>
                </Alert>
            </Card.Body>
        </Card>);
};
