import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { GET_PROFILE } from '../../../graphql/profile/queries';

interface IWelcome {

}

const Welcome: React.FC<IWelcome> = ({
}) => {

    const { data, error, loading } = useQuery(GET_PROFILE);
    const [name, setName] = useState("");

    useEffect(() => {
        if (!loading && !error) {
            setName(data.profile?.firstName);
        }
    });

    return (
        <h1 className="welcome-title">Welcome back,&nbsp;
            <span className="welcome-title-name">{name}</span>
        </h1 >
    );
};

export default Welcome;