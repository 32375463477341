import { useEffect, useState } from 'react';
import { MembershipTypeItem } from '../../../graphql/__generated__/graphql';

export const useMembershipTypes = ({ data }: any) => {

    const [membershipTypes, setMembershipTypes] = useState<MembershipTypeItem[]>();

    const restrictedTypes = ["HONORARY"];

    useEffect(() => {
        if (data?.membershipTypes && !membershipTypes)
            handleLoadMembershipTypes();
    });

    const handleLoadMembershipTypes = () => {
        try {
            setMembershipTypes(data.membershipTypes.filter((x: MembershipTypeItem) => {
                return !restrictedTypes.includes(x.name?.toUpperCase() ?? "");
            }).map((x: MembershipTypeItem) => { 
                return {
                    membershipTypeId: x.membershipTypeId,
                    name: x.name
                };
            }) as MembershipTypeItem[]);
        } catch (error) {
            console.error('Error processing membership types:', error);
        }
    };

    return {
        models: {
            membershipTypes
        }
    };
};
