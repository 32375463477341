import { useLazyQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { CHECK_USERNAME } from '../../../graphql/member';


interface UsernameInputProps {
    className: string,
    required: boolean,
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}


const useUsernameInputComponent = (value: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
    const [username, setUsername] = useState(value);
    const [usernameTaken, setUsernameTaken] = useState(null);
    const [invalidEmail, setInvalidEmail] = useState(false);

    const [check, { loading, error, data, refetch }] = useLazyQuery(CHECK_USERNAME, {
        variables: { username },
        fetchPolicy: 'network-only', // Doesn't check cache before making a network request
    });

    const handleCheckUsername = () => {

        if (!loading && data) {
            setUsernameTaken(data?.checkUsername);
        }
    }

    const handleUpdateUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        const uName = e.target.value;
        setUsername(uName);
        onChange(e);
    };

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setInvalidEmail(!emailRegex.test(username));

        if (emailRegex.test(username))
            refetch({ username }).then((r: any) => {

                console.log("checking username", r)

                setUsernameTaken(r?.data.checkUsername);
            })

    }, [username])

    return {
        models: { username, usernameTaken, invalidEmail },
        operations: { handleUpdateUsername, handleCheckUsername }
    }

}


const UsernameInput: React.FC<UsernameInputProps> = ({ className, required, value, onChange }) => {

    const { models, operations } = useUsernameInputComponent(value, onChange);

    useEffect(() => {
        operations.handleUpdateUsername({
            target: {
                value
            }
        } as React.ChangeEvent<HTMLInputElement>)
    }, [value])

    return (
        <Form.Group className={className} data-testid="user-account">
            <Form.Label>Username</Form.Label>
            <Form.Control
                required={required}
                type="email"
                name="username"
                placeholder="please enter an email address for your username"
                value={models.username}
                onChange={operations.handleUpdateUsername}
                isInvalid={models.usernameTaken || models.invalidEmail}
                isValid={!models.usernameTaken && !models.invalidEmail && !!models.username}
            />

            {models.usernameTaken && (
                <Form.Control.Feedback type="invalid">
                    Username is already in use.
                </Form.Control.Feedback>
            )}

            {models.invalidEmail && (
                <Form.Control.Feedback type="invalid">
                    Username must be a valid email address.
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default UsernameInput;
